import React from 'react';
import GeneralInput from '../../GeneralInput';
import SectionHeadline from '../ui/sectionHeadline';

const VaccineForm = ({ data, updateData }) => {
  const handleInputChange = (id, key, value) => {
    updateData(id, key, value);
  };

  return (
    <div className="p-4">
      <SectionHeadline sectionName="Flu Vaccine" />
      <GeneralInput
        id="fluVaccine_1"
        type="conditional-radio-two"
        label="Zuschuss durch Satzungsleistung"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.fluVaccine_1?.value || ''}
        onChange={(value) => handleInputChange('fluVaccine_1', 'value', value)}
        conditionalValues={{
          text1: {
            value: data?.fluVaccine_1?.valueOption1 || '',
            label: 'Wenn ja, wie hoch?',
            placeholder: 'Höhe in € eingeben',
            onChange: (value) => handleInputChange('fluVaccine_1', 'valueOption1', value),
          },
          text2: {
            value: data?.fluVaccine_1?.valueOption2 || '',
            label: 'Wenn ja, wie oft?',
            placeholder: 'Häufigkeit in Monaten eingeben',
            onChange: (value) => handleInputChange('fluVaccine_1', 'valueOption2', value),
          },
        }}
      />
      <GeneralInput
        id="fluVaccine_2"
        type="conditional-radio-two"
        label="Zuschuss durch Gesundheitskonto / Punkteprogramm"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.fluVaccine_2?.value || ''}
        onChange={(value) => handleInputChange('fluVaccine_2', 'value', value)}
        conditionalValues={{
          text1: {
            value: data?.fluVaccine_2?.valueOption1 || '',
            label: 'Wenn ja, wie hoch?',
            placeholder: 'Höhe in € eingeben',
            onChange: (value) => handleInputChange('fluVaccine_2', 'valueOption1', value),
          },
          text2: {
            value: data?.fluVaccine_2?.valueOption2 || '',
            label: 'Wenn ja, wie oft?',
            placeholder: 'Häufigkeit in Monaten eingeben',
            onChange: (value) => handleInputChange('fluVaccine_2', 'valueOption2', value),
          },
        }}
      />

      <SectionHeadline sectionName="Travel Vaccine" />
      <GeneralInput
        id="travelVaccine_1"
        type="conditional-radio-two"
        label="Zuschuss durch Satzungsleistung"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.travelVaccine_1?.value || ''}
        onChange={(value) => handleInputChange('travelVaccine_1', 'value', value)}
        conditionalValues={{
          text1: {
            value: data?.travelVaccine_1?.valueOption1 || '',
            label: 'Wenn ja, wie hoch?',
            placeholder: 'Höhe in € eingeben',
            onChange: (value) => handleInputChange('travelVaccine_1', 'valueOption1', value),
          },
          text2: {
            value: data?.travelVaccine_1?.valueOption2 || '',
            label: 'Wenn ja, wie oft?',
            placeholder: 'Häufigkeit in Monaten eingeben',
            onChange: (value) => handleInputChange('travelVaccine_1', 'valueOption2', value),
          },
        }}
      />
      <GeneralInput
        id="travelVaccine_2"
        type="conditional-radio-two"
        label="Zuschuss durch Gesundheitskonto / Punkteprogramm"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.travelVaccine_2?.value || ''}
        onChange={(value) => handleInputChange('travelVaccine_2', 'value', value)}
        conditionalValues={{
          text1: {
            value: data?.travelVaccine_2?.valueOption1 || '',
            label: 'Wenn ja, wie hoch?',
            placeholder: 'Höhe in € eingeben',
            onChange: (value) => handleInputChange('travelVaccine_2', 'valueOption1', value),
          },
          text2: {
            value: data?.travelVaccine_2?.valueOption2 || '',
            label: 'Wenn ja, wie oft?',
            placeholder: 'Häufigkeit in Monaten eingeben',
            onChange: (value) => handleInputChange('travelVaccine_2', 'valueOption2', value),
          },
        }}
      />
    </div>
  );
};

export default VaccineForm;
