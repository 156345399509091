import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// Add the ResizeObserver loop suppression code here
const resizeObserverErrLoop = () => {
  const resizeObserverErrDiv = document.createElement('div');
  const resizeObserverErrEvt = new ErrorEvent('error', {
    message: 'ResizeObserver loop limit exceeded',
    filename: 'resize-observer.js',
    lineno: 0,
    colno: 0,
  });

  window.addEventListener('error', (e) => {
    if (e.message === 'ResizeObserver loop limit exceeded') {
      e.stopImmediatePropagation();
    }
  });

  resizeObserverErrDiv.dispatchEvent(resizeObserverErrEvt);
};

// Call the function to suppress the error
resizeObserverErrLoop();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
