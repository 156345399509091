import React from "react";
import translateSection from "../../../utils/translateSection";

function SectionHeadline(props) {
    const {sectionName} = props;
  return (
    <h2
      style={{ color: "#00508b", size: "20px", fontFamily: "MiloMedium" }}
      className="font-semibold text-lg text-blue-800 mb-3 underline decoration-2 underline-offset-2"
    >
      {translateSection(sectionName)}
    </h2>
  );
}

export default SectionHeadline;