import React from 'react';
import GeneralInput from '../../GeneralInput';
import SectionHeadline from '../ui/sectionHeadline';

const SportsForm = ({ data, updateData }) => {
  const handleInputChange = (id, key, value) => {
    updateData(id, key, value);
  };

  return (
    <div className="p-4">
      <SectionHeadline sectionName="Medical Sport Examination" />
      <GeneralInput
        id="medicalSportExamination_1"
        type="conditional-radio-two"
        label="Zuschuss durch Satzungsleistung"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.medicalSportExamination_1?.value || ''}
        onChange={(value) => handleInputChange('medicalSportExamination_1', 'value', value)}
        conditionalValues={{
          text1: {
            value: data?.medicalSportExamination_1?.valueOption1 || '',
            label: 'Wenn ja, wie hoch?',
            placeholder: 'Höhe in € eingeben',
            onChange: (value) => handleInputChange('medicalSportExamination_1', 'valueOption1', value),
          },
          text2: {
            value: data?.medicalSportExamination_1?.valueOption2 || '',
            label: 'Wenn ja, wie oft?',
            placeholder: 'Häufigkeit in Monaten eingeben',
            onChange: (value) => handleInputChange('medicalSportExamination_1', 'valueOption2', value),
          },
        }}
      />
      <GeneralInput
        id="medicalSportExamination_2"
        type="conditional-radio-two"
        label="Zuschuss durch Gesundheitskonto / Punkteprogramm"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.medicalSportExamination_2?.value || ''}
        onChange={(value) => handleInputChange('medicalSportExamination_2', 'value', value)}
        conditionalValues={{
          text1: {
            value: data?.medicalSportExamination_2?.valueOption1 || '',
            label: 'Wenn ja, wie hoch?',
            placeholder: 'Höhe in € eingeben',
            onChange: (value) => handleInputChange('medicalSportExamination_2', 'valueOption1', value),
          },
          text2: {
            value: data?.medicalSportExamination_2?.valueOption2 || '',
            label: 'Wenn ja, wie oft?',
            placeholder: 'Häufigkeit in Monaten eingeben',
            onChange: (value) => handleInputChange('medicalSportExamination_2', 'valueOption2', value),
          },
        }}
      />
      <GeneralInput
        id="medicalSportExamination_3"
        type="textarea"
        label="Besondere Zusatzleistungen (Freitext)"
        placeholder=" Details eingeben"
        value={data?.medicalSportExamination_3?.value || ''}
        onChange={(value) => handleInputChange('medicalSportExamination_3', 'value', value)}
      />

      <SectionHeadline sectionName="Health Courses" />
      <GeneralInput
        id="healthCourses_1"
        type="conditional-radio-two"
        label="Zuschuss durch Satzungsleistung"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.healthCourses_1?.value || ''}
        onChange={(value) => handleInputChange('healthCourses_1', 'value', value)}
        conditionalValues={{
          text1: {
            value: data?.healthCourses_1?.valueOption1 || '',
            label: 'Wenn ja, wie hoch?',
            placeholder: 'Höhe in € eingeben',
            onChange: (value) => handleInputChange('healthCourses_1', 'valueOption1', value),
          },
          text2: {
            value: data?.healthCourses_1?.valueOption2 || '',
            label: 'Wenn ja, wie oft?',
            placeholder: 'Häufigkeit in Monaten eingeben',
            onChange: (value) => handleInputChange('healthCourses_1', 'valueOption2', value),
          },
        }}
      />
      <GeneralInput
        id="healthCourses_2"
        type="conditional-radio-two"
        label="Zuschuss durch Gesundheitskonto / Punkteprogramm"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.healthCourses_2?.value || ''}
        onChange={(value) => handleInputChange('healthCourses_2', 'value', value)}
        conditionalValues={{
          text1: {
            value: data?.healthCourses_2?.valueOption1 || '',
            label: 'Wenn ja, wie hoch?',
            placeholder: 'Höhe in € eingeben',
            onChange: (value) => handleInputChange('healthCourses_2', 'valueOption1', value),
          },
          text2: {
            value: data?.healthCourses_2?.valueOption2 || '',
            label: 'Wenn ja, wie oft?',
            placeholder: 'Häufigkeit in Monaten eingeben',
            onChange: (value) => handleInputChange('healthCourses_2', 'valueOption2', value),
          },
        }}
      />
      <GeneralInput
        id="healthCourses_3"
        type="textarea"
        label="Besondere Zusatzleistungen (Freitext)"
        placeholder=" Details eingeben"
        value={data?.healthCourses_3?.value || ''}
        onChange={(value) => handleInputChange('healthCourses_3', 'value', value)}
      />

      <SectionHeadline sectionName="Fitness Tracker" />
      <GeneralInput
        id="fitnessTracker_1"
        type="conditional-radio-two"
        label="Zuschuss durch Satzungsleistung"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.fitnessTracker_1?.value || ''}
        onChange={(value) => handleInputChange('fitnessTracker_1', 'value', value)}
        conditionalValues={{
          text1: {
            value: data?.fitnessTracker_1?.valueOption1 || '',
            label: 'Wenn ja, wie hoch?',
            placeholder: 'Höhe in € eingeben',
            onChange: (value) => handleInputChange('fitnessTracker_1', 'valueOption1', value),
          },
          text2: {
            value: data?.fitnessTracker_1?.valueOption2 || '',
            label: 'Wenn ja, wie oft?',
            placeholder: 'Häufigkeit in Monaten eingeben',
            onChange: (value) => handleInputChange('fitnessTracker_1', 'valueOption2', value),
          },
        }}
      />
      <GeneralInput
        id="fitnessTracker_2"
        type="conditional-radio-two"
        label="Zuschuss durch Gesundheitskonto / Punkteprogramm"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.fitnessTracker_2?.value || ''}
        onChange={(value) => handleInputChange('fitnessTracker_2', 'value', value)}
        conditionalValues={{
          text1: {
            value: data?.fitnessTracker_2?.valueOption1 || '',
            label: 'Wenn ja, wie hoch?',
            placeholder: 'Höhe in € eingeben',
            onChange: (value) => handleInputChange('fitnessTracker_2', 'valueOption1', value),
          },
          text2: {
            value: data?.fitnessTracker_2?.valueOption2 || '',
            label: 'Wenn ja, wie oft?',
            placeholder: 'Häufigkeit in Monaten eingeben',
            onChange: (value) => handleInputChange('fitnessTracker_2', 'valueOption2', value),
          },
        }}
      />
      <GeneralInput
        id="fitnessTracker_3"
        type="textarea"
        label="Besondere Zusatzleistungen (Freitext)"
        placeholder=" Details eingeben"
        value={data?.fitnessTracker_3?.value || ''}
        onChange={(value) => handleInputChange('fitnessTracker_3', 'value', value)}
      />

      <SectionHeadline sectionName="Online Courses" />
      <GeneralInput
        id="onlineCourses_1"
        type="conditional-radio-two"
        label="Zuschuss durch Satzungsleistung"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.onlineCourses_1?.value || ''}
        onChange={(value) => handleInputChange('onlineCourses_1', 'value', value)}
        conditionalValues={{
          text1: {
            value: data?.onlineCourses_1?.valueOption1 || '',
            label: 'Wenn ja, wie hoch?',
            placeholder: 'Höhe in € eingeben',
            onChange: (value) => handleInputChange('onlineCourses_1', 'valueOption1', value),
          },
          text2: {
            value: data?.onlineCourses_1?.valueOption2 || '',
            label: 'Wenn ja, wie oft?',
            placeholder: 'Häufigkeit in Monaten eingeben',
            onChange: (value) => handleInputChange('onlineCourses_1', 'valueOption2', value),
          },
        }}
      />
      <GeneralInput
        id="onlineCourses_2"
        type="conditional-radio-two"
        label="Zuschuss durch Gesundheitskonto / Punkteprogramm"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.onlineCourses_2?.value || ''}
        onChange={(value) => handleInputChange('onlineCourses_2', 'value', value)}
        conditionalValues={{
          text1: {
            value: data?.onlineCourses_2?.valueOption1 || '',
            label: 'Wenn ja, wie hoch?',
            placeholder: 'Höhe in € eingeben',
            onChange: (value) => handleInputChange('onlineCourses_2', 'valueOption1', value),
          },
          text2: {
            value: data?.onlineCourses_2?.valueOption2 || '',
            label: 'Wenn ja, wie oft?',
            placeholder: 'Häufigkeit in Monaten eingeben',
            onChange: (value) => handleInputChange('onlineCourses_2', 'valueOption2', value),
          },
        }}
      />
      <GeneralInput
        id="onlineCourses_3"
        type="textarea"
        label="Besondere Zusatzleistungen (Freitext)"
        placeholder=" Details eingeben"
        value={data?.onlineCourses_3?.value || ''}
        onChange={(value) => handleInputChange('onlineCourses_3', 'value', value)}
      />
    </div>
  );
};

export default SportsForm;
