const translateSection = (section) => {
    const sections = {
      pzr: "Fragen PZR",
      dentures: "Vergünstigter Zahnersatz",
      toothcorrection: "Vergünstigte Zahnkorrektur",
      fluvaccine: "Grippeschutzimpfung (für Nicht-Risikogruppen und <60 jährige)",
      travelvaccine: "Reiseschutzimpfungen",
      breastcancer: "Brustkrebsvorsorge außerhalb der gesetzlichen Leistungen",
      colorectalcancer:"Darmkrebsvorsorge außerhalb der gesetzlichen Leistungen",
      skincancer: "Hautkrebsvorsorge außerhalb der gesetzlichen Leistungen",
      osteopathy: "Osteopathie",
      homeopathy: "Homöopathie",
      acupuncture: "Akkupunktur",
      homeopathicmedicine: "Homöopathische Arzneimittel",
      medicalsportexamination: "Sportmed. Untersuchungen",
      healthcourses: "Gesundheitskurse",
      fitnesstracker: "Zuschuss Fitness - Trackern",
      onlinecourses: "Online - Kurse",
      hotline: "Servicetelefon 24/7",
      telemedicine: "Tele-Medizin",
      artificialinsemination: "Künstliche Befruchtung",
      pregnancycare: "Schwangerschaftsvorsorge",
      midwifeservice: "Hebammenrufbereitschaft",
      
  
  
  
  
    };
    if(section.replace(/ /g, "").toLowerCase() in sections){
      return sections[section.replace(/ /g, "").toLowerCase()];
    }else{return section;}
    
  };
  
  export default translateSection;